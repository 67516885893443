import { Route, Routes, useNavigate } from 'react-router-dom';

import Contracts from '../contracts';
import Counterparties from '../counterparties/Counterparties';
import Infopanel from '../infopanel/Infopanel';
import Organizations from '../organization/Organization';
import { Staff } from '../staff/Staff';
import PowersAttorney from '../powers-attorney/PowersAttorney';
import Settings from '../settings/Settings';

import { Profile } from '../profile/Profile';

import { CounterpatyDetail } from '../counterparties/counterparty-detail/CounterpatyDetail';
import { InvitationContent } from '../invitation';

import { AppRoutes } from 'config/routes';

import s from './DashboardPage.module.scss';
import { useLimits } from 'hooks';
import { useEffect } from 'react';
import OperationalRequests from '../operational-requests';
import ShipmentTasks from '../shipment-tasks';
import ControlCards from '../control-cards';
import { OrganizationProfile } from '../organization-profile/OrganizationProfile';
import ShipmentReports from '../shipment-reports';
import ShipmentRegistry from '../shipment-registry';
import { Theme } from '../theme/Theme';

export function DashboardPage() {
  const navigate = useNavigate();
  const { isFetching, isLimitedUser } = useLimits();

  const {
    Profile: ProfileRoute,
    Settings: SettingsRoute,
    Contracts: ContractsRoute,
    Infopanel: InfopanelRoute,
    Organizations: OrganizationRoute,
    ShipmentTasks: ShipmentTasksRoute,
    ShipmentReports: ShipmentReportsRoute,
    ControlCards: ControlCardsRoute,
    Counterparties: CounterpartiesRoute,
    PowersAttorney: PowersAttorneyRoute,
    OrganizationProfile: OrganizationProfileRoute,
    OperationalRequests: OperationalRequestsRoute,
    ShipmentRegistry: ShipmentRegistryRoute,
  } = AppRoutes.Dashboard;

  useEffect(() => {
    if (!isFetching && isLimitedUser) {
      if (!window.location.pathname.includes(PowersAttorneyRoute.Home)) {
        // navigate(PowersAttorneyRoute.Home + PowersAttorneyRoute.Active);
        navigate(AppRoutes.Auth.Home + AppRoutes.Auth.UserRestricted);
      }
    }
  }, [isFetching, isLimitedUser]);

  return (
    <div className={s.container}>
      <Routes>
        <Route path={InfopanelRoute + '/*'} element={<Infopanel />} />
        <Route path={CounterpartiesRoute.Home + '/*'} element={<Counterparties />} />
        <Route
          path={
            CounterpartiesRoute.Home +
            CounterpartiesRoute.CounterpatyDetail.Home +
            '/:id' +
            '/*'
          }
          element={<CounterpatyDetail />}
        />
        <Route
          path={CounterpartiesRoute.Home + CounterpartiesRoute.Invitation.Home + '/*'}
          element={<InvitationContent />}
        />
        <Route path={ContractsRoute.Home + '/*'} element={<Contracts />} />
        <Route path={PowersAttorneyRoute.Home + '/*'} element={<PowersAttorney />} />

        <Route path={ProfileRoute.Home + '/*'} element={<Profile />} />
        <Route
          path={OrganizationProfileRoute.Home + '/:id'}
          element={<OrganizationProfile />}
        />
        <Route path={OrganizationRoute.Home + '/*'} element={<Organizations />} />
        <Route
          path={OrganizationRoute.Home + OrganizationRoute.Staff + '/*'}
          element={<Staff />}
        />
        <Route path={OperationalRequestsRoute.Home + '/*'} element={<OperationalRequests />} />
        <Route path={ShipmentTasksRoute.Home + '/*'} element={<ShipmentTasks />} />
        <Route path={ControlCardsRoute.Home + '/*'} element={<ControlCards />} />
        <Route path={ShipmentReportsRoute.Home + '/*'} element={<ShipmentReports />} />
        <Route path={ShipmentRegistryRoute.Home + '/*'} element={<ShipmentRegistry />} />
        <Route
          path={SettingsRoute.Home + SettingsRoute.EDOOperator + '/*'}
          element={<Settings />}
        />
      </Routes>
    </div>
  );
}
