import cx from 'classnames';
import { useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { HeaderTabs, Tab } from 'components/header-tabs/header-tabs';
import { AsideOrganization } from 'components/modal';
import { AsideAddDocument } from 'components/modal/aside-organization/aside-document/aside-document';
import { createButtonSettings } from '../counterparties/counterparty-detail/CounterpatyDetail';

import { DocumentsContent } from './documents/Documents';

import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { Loader } from 'components/loader/loader';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';

import s from './Organization.module.scss';

import { AppRoutes } from 'config/routes';
import { useGetMyPaymentOptionsQuery } from 'store/api/payments';
import { DefaultPaymentOption, Organization } from 'store/api/types';
import { RequisitesDetail } from '../counterparties/counterparty-detail/requisites/RequisitesDetail';
import { Theme } from '../theme/Theme';

const { Home, Organizations: OrganizationsRoute } = AppRoutes.Dashboard;

const tabsContent: Tab[] = [
  {
    id: 0,
    label: 'Requisites',
    path: Home + OrganizationsRoute.Home + OrganizationsRoute.Requisites,
  },
  {
    id: 1,
    label: 'Documents',
    path: Home + OrganizationsRoute.Home + OrganizationsRoute.Documents,
  },
  {
    id: 2,
    label: 'Themes',
    path: Home + OrganizationsRoute.Home + OrganizationsRoute.Themes,
  },
];

function Organizations() {
  const location = useLocation();

  const { data: myOrganizations, isFetching } = useGetMyOrganizationsQuery();
  const { data: myPayments = [] } = useGetMyPaymentOptionsQuery();

  const currentOrganization: Organization | undefined = myOrganizations?.[0];
  const currentPayment: DefaultPaymentOption | undefined = myPayments?.[0];

  const isEditingButton = location.pathname?.includes(OrganizationsRoute?.Requisites);
  const isThemeButton = location.pathname?.includes(OrganizationsRoute?.Themes);

  const classesButton = cx(s['button']);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isAddDOcument, setIsAddDocumnet] = useState<boolean>(false);

  const handleOpenEditing = () => {
    setIsEditing(true);
  };

  const handleCloseEditing = () => {
    setIsEditing(false);
  };

  const handleOpenDocumnt = () => {
    setIsAddDocumnet(true);
  };

  const handleCloseDocument = () => {
    setIsAddDocumnet(false);
  };

  const buttonSettings = useMemo(() => {
    if (isEditingButton) {
      return createButtonSettings({
        text: 'Edit',
        icon: <EditIcon width={16} height={16} />,
        type: 'primary',
        disabled: false,
        className: classesButton,
        onClick: handleOpenEditing,
      });
    }

    if (isThemeButton) {
      return createButtonSettings({
        text: 'Add theme',
        icon: <EditIcon width={16} height={16} />,
        type: 'primary',
        disabled: false,
        className: s.none,
        onClick: handleOpenEditing,
      });
    }

    return createButtonSettings({
      text: 'Add document',
      icon: <EditIcon width={16} height={16} />,
      type: 'primary',
      disabled: false,
      className: classesButton,
      onClick: handleOpenDocumnt,
    });
  }, [isEditingButton, isThemeButton]);

  if (isFetching) {
    return <Loader size="medium" />;
  }

  return (
    <div className={s['container']}>
      <div className={s['container-header']}>
        <span className={s['title']}>{currentOrganization?.name}</span>
        <div className={s['wrapper-attr']}>
          <span className={s['label-attr']}>ID:</span>
          <span className={s['value-attr']}>{currentOrganization?.id}</span>
        </div>
      </div>
      <div className={s['wrapper-content']}>
        <HeaderTabs isButton type="detail" tabs={tabsContent} optionButton={buttonSettings} />

        <Routes>
          <Route path="/*" element={<></>} />
          <Route
            path={OrganizationsRoute.Requisites}
            element={<RequisitesDetail data={currentOrganization} payment={currentPayment} />}
          />
          <Route path={OrganizationsRoute.Documents} element={<DocumentsContent />} />
          <Route path={OrganizationsRoute.Themes} element={<Theme />} />
        </Routes>
      </div>

      <AsideOrganization
        isOpen={isEditing}
        payment={currentPayment}
        handleClose={handleCloseEditing}
        organization={currentOrganization}
      />
      <AsideAddDocument isOpen={isAddDOcument} onClick={handleCloseDocument} />
    </div>
  );
}

export default Organizations;
