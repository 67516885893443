import { FormatPainterOutlined } from '@ant-design/icons';
import { Icons } from 'components/icons';
import { AppRoutes } from './routes';

export interface LinkProps {
  path: string;
  label: string;
  id: number | string;
  icon?: JSX.Element;
  subLinks?: LinkProps[];
}

export const Infopanel = {
  id: 0,
  label: 'Infopanel',
  path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.Infopanel,
};

export const Contracts = {
  id: 1,
  icon: <Icons.EditDocument />,
  label: 'Contracts',
  path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.Contracts.Home,
};

export const PowersAttorney = {
  id: 2,
  icon: <Icons.Diploma />,
  label: 'Powers of attorney',
  path:
    AppRoutes.Dashboard.Home +
    AppRoutes.Dashboard.PowersAttorney.Home +
    AppRoutes.Dashboard.PowersAttorney.Active,
};

export const Counterparties = {
  id: 3,
  icon: <Icons.Report />,
  label: 'Counterparties',
  path:
    AppRoutes.Dashboard.Home +
    AppRoutes.Dashboard.Counterparties.Home +
    AppRoutes.Dashboard.Counterparties.CounterpatyDetail.Home,
};

export const Organizations = {
  id: 4,
  icon: <Icons.EditDocument />,
  label: 'Organizations',
  path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.Organizations.Home + 'organizations',
  subLinks: [
    {
      id: 6,
      label: 'Organization profile',
      path:
        AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Organizations.Home +
        AppRoutes.Dashboard.Organizations.Requisites,
    },
    {
      id: 7,
      label: 'Staffs',
      path:
        AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Organizations.Home +
        AppRoutes.Dashboard.Organizations.Staff +
        AppRoutes.Dashboard.Organizations.List,
    },
  ],
};

export const OperationalRequests = {
  id: 5,
  icon: <Icons.Ambulance />,
  label: 'Fuel supply',
  path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.OperationalRequests.Home,
  subLinks: [
    {
      id: 7,
      label: 'Operational requests',
      path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.OperationalRequests.Home,
    },
    {
      id: 8,
      label: 'Shipment tasks',
      path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.ShipmentTasks.Home,
    },
    {
      id: 9,
      label: 'Control cards',
      path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.ControlCards.Home,
    },
    {
      id: 10,
      label: 'Shipment reports',
      path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.ShipmentReports.Home,
    },
    {
      id: 11,
      label: 'Shipment registry',
      path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.ShipmentRegistry.Home,
    },
  ],
};

export const Settings = {
  id: 5,
  label: 'Settings',
  path:
    AppRoutes.Dashboard.Home +
    AppRoutes.Dashboard.Settings.Home +
    AppRoutes.Dashboard.Settings.EDOOperator,
  subLinks: [
    {
      id: 6,
      label: 'Settings. Common',
      path:
        AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Settings.Home +
        AppRoutes.Dashboard.Settings.SettingsCommon,
    },
    {
      id: 7,
      label: 'EDO Operation',
      path:
        AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Settings.Home +
        AppRoutes.Dashboard.Settings.EDOOperator,
    },
  ],
};

export const linksGeneral: LinkProps[] = [
  // Infopanel,
  Contracts,
  PowersAttorney,
  Counterparties,
  Organizations,
  OperationalRequests,
  //Settings,
];

export const linksLimitedUser: LinkProps[] = [PowersAttorney];
