import { useState } from 'react';
import s from './Theme.module.scss'; // Ensure you have a corresponding CSS/SCSS file for styling

const themes = [
  {
    '--dashboard-sidepanel': '#2d2eb7',
    '--dashboard-sidepanel-border': '1px solid #8997ae',
    '--dashboard-item-background-hover': '#000073',
    '--dashboard-item-text-inactive': '#8997AE',
    '--dashboard-item-text': '#FFF',
    '--dashboard-main-bg': '#E0E5EC',
  },
  {
    '--dashboard-sidepanel': '#009c43',
    '--dashboard-sidepanel-border': '1px solid #c4ced7',
    '--dashboard-item-background-hover': '#fff',
    '--dashboard-item-text-inactive': '#fff',
    '--dashboard-item-text': '#009c43',
    '--dashboard-main-bg': '#e5f1da',
  },
  {
    '--dashboard-sidepanel': '#1f1f1f',
    '--dashboard-sidepanel-border': '1px solid #333',
    '--dashboard-item-background-hover': '#444',
    '--dashboard-item-text-inactive': '#888',
    '--dashboard-item-text': '#FFF',
    '--dashboard-main-bg': '#282828',
  },
  {
    '--dashboard-sidepanel': '#004f73',
    '--dashboard-sidepanel-border': '1px solid #006c91',
    '--dashboard-item-background-hover': '#005f85',
    '--dashboard-item-text-inactive': '#a3d3e6',
    '--dashboard-item-text': '#fff',
    '--dashboard-main-bg': '#e3f5fb',
  },
  {
    '--dashboard-sidepanel': '#ff4500',
    '--dashboard-sidepanel-border': '1px solid #ff7043',
    '--dashboard-item-background-hover': '#ff6347',
    '--dashboard-item-text-inactive': '#ffe0b3',
    '--dashboard-item-text': '#fff',
    '--dashboard-main-bg': '#ffe6cc',
  },
  {
    '--dashboard-sidepanel': '#2e8b57',
    '--dashboard-sidepanel-border': '1px solid #3cb371',
    '--dashboard-item-background-hover': '#66cdaa',
    '--dashboard-item-text-inactive': '#b2dfdb',
    '--dashboard-item-text': '#fff',
    '--dashboard-main-bg': '#e8f5e9',
  },
];

const ThemePreview = ({
  theme,
  isSelected,
  onSelect,
}: {
  theme: any;
  isSelected: boolean;
  onSelect: () => void;
}) => {
  const HoverItem = () => {
    return (
      <div
        className={s['nav-item']}
        style={{
          backgroundColor: theme['--dashboard-item-background-hover'],
        }}
      >
        <div
          className={s['sub-nav-item']}
          style={{
            backgroundColor: theme['--dashboard-item-text'],
          }}
        />
      </div>
    );
  };

  const InactiveItem = () => {
    return (
      <div
        className={s['nav-item']}
        style={{
          backgroundColor: theme['--dashboard-item-text-inactive'],
        }}
      >
        <div
          className={s['sub-nav-item']}
          style={{
            backgroundColor: theme['--dashboard-item-text-inactive'],
          }}
        />
      </div>
    );
  };

  return (
    <div onClick={onSelect} className={s['preview-container']}>
      <div className={s['radio-button']}>
        {
          <div
            className={s['radio-button-inner']}
            style={{
              backgroundColor: isSelected ? '#000073' : theme['--dashboard-item-text-inactive'],
            }}
          />
        }
      </div>

      <div
        style={{
          backgroundColor: theme['--dashboard-sidepanel'],
          border: theme['--dashboard-sidepanel-border'],
        }}
        className={s['left-panel']}
      >
        <InactiveItem />
        <HoverItem />
        <InactiveItem />
        <InactiveItem />
        <InactiveItem />
      </div>
      <div
        className={s['right-panel']}
        style={{
          backgroundColor: theme['dashboard-main-bg'],
        }}
      >
        <div className={s['header']} />
        <div className={s['content-item']} />
        <div className={s['content-item']} />
        <div className={s['content-item']} />
      </div>
    </div>
  );
};

export const Theme = () => {
  const [selectedTheme, setSelectedTheme] = useState(0);

  const applyTheme = (index: number) => {
    const themeName = `theme-${index}`;
    document.documentElement.setAttribute('data-theme', themeName);
    localStorage.setItem('selectedTheme', themeName);
  };

  const handleSelectTheme = (index: number) => {
    setSelectedTheme(index);
    applyTheme(index + 1);
  };

  return (
    <div className={s['theme-screen']}>
      <h2>Выберите тему</h2>
      <div className={s['previews-wrapper']}>
        {themes.map((item, index) => (
          <ThemePreview
            key={index}
            theme={item}
            isSelected={selectedTheme === index}
            onSelect={() => handleSelectTheme(index)}
          />
        ))}
      </div>
    </div>
  );
};
