import { useEffect, useLayoutEffect } from 'react';

export const useRestoreTheme = () => {
  const defaultTheme = 'theme-1';

  useLayoutEffect(() => {
    const applyTheme = (themeName: string) => {
      document.documentElement.setAttribute('data-theme', themeName);
    };

    const savedTheme = localStorage.getItem('selectedTheme');
    applyTheme(savedTheme?.length ? savedTheme : defaultTheme);
  }, []);
};
